import * as React from "react"

type GalleryImageProps = {
    src: string;
}

export default (props: GalleryImageProps) => {
    return (
      <div className="flex flex-wrap w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
        <div className="w-full p-1 md:p-2">
          <a className="spotlight" href={props.src}><img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
            src={props.src} /></a>
        </div>
      </div>
    )
};
