import * as React from "react"
import { Helmet } from 'react-helmet'
import { PageProps } from "gatsby"
import Menu from "../components/Menu";

import homeHeader from "../images/projects/hm/p1.jpg"

import GalleryImage from "../components/GalleryImage";
import Footer from "../components/Footer";

import hm1 from "../images/home-gallery/hm1.jpg"
import hm2 from "../images/home-gallery/hm2.jpg"
import hm3 from "../images/home-gallery/hm3.jpg"

import r1 from "../images/home-gallery/r1.jpg"
import r2 from "../images/home-gallery/r2.jpg"
import r3 from "../images/home-gallery/r3.jpg"
import r4 from "../images/home-gallery/r4.jpg"
import r5 from "../images/home-gallery/r5.jpg"

import w1 from "../images/home-gallery/w1.jpg"
import w2 from "../images/home-gallery/w2.jpg"
import w3 from "../images/home-gallery/w3-thumb.jpg"
import w3mov from "../images/home-gallery/w3.mp4"
import w4 from "../images/home-gallery/w4.jpg"
import w5 from "../images/home-gallery/w5.jpg"
import w6 from "../images/home-gallery/w6.jpg"
import w7 from "../images/home-gallery/w7-thumb.jpg"
import w7mov from "../images/home-gallery/w7.mp4"
import w8 from "../images/home-gallery/w8.jpg"

const Index: React.FC<PageProps> = () => {
  return (
    <div className="min-h-screen">
      {/* @ts-ignore */}
      <Helmet title="Elbae">
        <script src="https://rawcdn.githack.com/nextapps-de/spotlight/0.7.8/dist/spotlight.bundle.js"></script>
      </Helmet>
      
      <Menu />

      <main className="flex flex-col mt-6">
          <div className="w-full h-[70rem] bg-cover bg-center" style={{
              backgroundImage: `linear-gradient(0deg, rgba(0,0,0, 0.5), rgba(0,0,0, 0.3)),url(${homeHeader})`,
              backgroundPosition: 'center top'
            }}>
            <h1 className="font-superclarendon text-secondary mt-96 lg:mt-[50rem] text-6xl block w-full m-auto text-center">MEET ELBAE</h1>
          </div>
          <section className="overflow-hidden bg-secondary">
            <div className="w-full px-5 py-2 mx-auto lg:py-12 lg:px-32">
              <div className="flex flex-wrap -m-1 md:-m-2">
                <GalleryImage src={hm1} />
                <GalleryImage src={hm2} />
                <GalleryImage src={hm3} />

                <GalleryImage src={r1} />
                <GalleryImage src={r2} />
                <GalleryImage src={r3} />
                <GalleryImage src={r4} />
                <GalleryImage src={r5} />

                <GalleryImage src={w1} />
                <GalleryImage src={w2} />
                <div className="flex flex-wrap w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
                  <div className="w-full p-1 md:p-2">
                    <a className="spotlight" data-media="video" data-src-mp4={w3mov} data-poster={w3} data-autoplay="true"><img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                      src={w3} /></a>
                  </div>
                </div>
                <GalleryImage src={w4} />
                <GalleryImage src={w5} />
                <GalleryImage src={w6} />
                <div className="flex flex-wrap w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
                  <div className="w-full p-1 md:p-2">
                    <a className="spotlight" data-media="video" data-src-mp4={w7mov} data-poster={w7} data-autoplay="true"><img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                      src={w7} /></a>
                  </div>
                </div>
                <GalleryImage src={w8} />
              </div>
            </div>
          </section>
      </main>

      <Footer />
    </div>
  );
}

export default Index
